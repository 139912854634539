<template>
  <header class="manatt-ventures-header">
    <nav class="navbar navbar-expand-md">
      <div class="container">
        <NuxtLink class="navbar-logo" to="/">
          <NuxtImg :src="logoUrl" width="139" height="66" />
        </NuxtLink>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarMain"
          aria-controls="navbarMain"
          aria-expanded="false"
          aria-label="Toggle Navigation"
        >
          <i class="fas fa-bars" aria-hidden="true"></i>
        </button>
        <div id="navbarMain" class="collapse navbar-collapse">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <NuxtLink class="nav-link" to="/manatt-ventures">Home</NuxtLink>
            </li>
            <li class="nav-item">
              <NuxtLink class="nav-link" to="/manatt-ventures/services">Services</NuxtLink>
            </li>
            <li class="nav-item dropdown">
              <NuxtLink
                id="aboutMenu"
                class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About
              </NuxtLink>
              <div class="dropdown-menu" aria-labelledby="aboutMenu">
                <NuxtLink
                  class="dropdown-item"
                  to="/manatt-ventures/about/the-entrepreneur-program"
                >
                  The Entrepreneur Program
                </NuxtLink>
                <NuxtLink class="dropdown-item" to="/manatt-ventures/about/our-team">
                  Our Team
                </NuxtLink>
                <NuxtLink class="dropdown-item" to="/manatt-ventures/about/key-industries">
                  Key Industries
                </NuxtLink>
              </div>
            </li>
            <li class="nav-item dropdown">
              <NuxtLink
                id="resourceCenterMenu"
                class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Resource Center
              </NuxtLink>
              <div class="dropdown-menu" aria-labelledby="resourceCenterMenu">
                <NuxtLink
                  class="dropdown-item"
                  to="/manatt-ventures/resource-center/thought-leadership"
                >
                  Thought Leadership
                </NuxtLink>
                <NuxtLink class="dropdown-item" to="/manatt-ventures/resource-center/quick-links">
                  Quick Links
                </NuxtLink>
              </div>
            </li>
            <li class="nav-item">
              <NuxtLink class="nav-link" data-bs-toggle="modal" data-bs-target="#navbar-search">
                <IconMagnifyingGlass />
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="box-search-left">
      <div class="box-search-left-top"><div></div></div>
      <div
        id="navbar-search"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="search"
        aria-hidden="true"
        style="display: none"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <input type="text" class="form-control" autocomplete="off" />
              <button class="btn-manatt-gold btn btn-default"> Search </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script lang="ts" setup>
const siteStore = useSiteStore()
const favIconUrl = computed(() => siteStore.siteConfig.elements?.favicon?.value[0]?.url || '')

useHead(() => ({
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: favIconUrl.value
    }
  ]
}))

const logoUrl = ref('')
if (siteStore.siteConfig.elements.websiteLogo.value.length > 0)
  logoUrl.value = siteStore.siteConfig.elements.websiteLogo.value[0].url
</script>
